
    .res-rightarea {
        width: 240px;
        margin-top: -42px;
        padding: 0 15px;
        .recommend-course {
            color: #595959;
            font-size: 14px;
            margin: 18px 0 18px 2px;
        }
        .res-ads {
            margin-left: 2px;
            div {
                overflow: hidden;
                width: 240px;
                height: 160px;
                border-radius: 5px;
                margin: 0 auto 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
